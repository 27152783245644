import React, { ReactElement, useState } from 'react'
import SEO from '../components/util/seo/Seo'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layouts/main/Layout'
// import { i18n } from '../constants/i18n'
import HelloWorld from '../components/HelloWordHoodie/helloWorld/HelloWorld'
import Binary from '../components/HelloWordHoodie/binary/Binary'
import Snow from '../components/HelloWordHoodie/snow/Snow'
import Slider from '../components/productPage/slider/Slider'
import Separator from '../components/shared/separator/Separator'
import Follow from '../components/shared/follow/Follow'
import Vim from '../components/shared/vim/Vim'
import ConsoleContainer from '../components/HelloWordHoodie/consolewindow/ConsoleCotainer'

interface HomepageData {
  fields: {
    slug: string
  }
  frontmatter: {
    pageKey: string
    seoTitle: string
    seoDesc: string
    title: string
    text: string
  }
}

interface BlogPosts {
  node: {
    fields: {
      slug: string
    }
    frontmatter: {
      id: number
      seo_title: string
      seo_desc: string
      snipcartDesc: string
      title: string
      description: string
      weight: number
      available: string
      category: string
      date: string
      soldout: boolean
      titleSEO: string
      descriptionSEO: string
      featuredImage: string
      gallery: {
        alt: string
        image: string
      }
      locale: string
      modelinfo: string
      new: string
      price: string
      productinfo: string
      tags: string
      templateKey: string
      fabrics: {
        values: string
      }
      size: {
        name: string
        values: string
      }
    }
  }
}

interface IndexProps {
  pageContext: {
    [locale: string]: string
  }
  data: {
    homePageData: HomepageData
  }
}

const IndexPage = ({ pageContext: { locale }, ...props }: IndexProps): ReactElement => {
  return (
    <Layout locale={locale}>
      <SEO title={'Hello World and Merge Conflicts'} metaDescription={'TODO'} />
      <Binary />
      <HelloWorld />
      <Separator distance="small" />
      <Separator distance="small" />
      <Separator distance="large" />
      <div className="container-normal flex flex-wrap">
        <div className="w-full md:w-6/12 md:pt-3 md:pl-6">
          <Link to="mergeconf">
            <Slider picture1={'white_1.jpg'} />
            <p>Merge Conflict T-Shirt</p>
            <p>30 Euro</p>
          </Link>
        </div>
        <div className="w-full md:w-6/12 md:pt-3 md:pl-6">
          <Link to="helloworld">
            <Slider picture1={'front.jpg'} />
            <p>Hello World Hoodie</p>
            <p>45 Euro</p>
          </Link>
        </div>
      </div>
      <Separator distance="medium" />
      <div className="container-normal flex flex-wrap">
        <Vim locale={locale} black>
          <p className="whitespace-pre-line">{'TODO'}</p>
        </Vim>
      </div>
      <ConsoleContainer />
      <Separator distance="large" />
      <Follow />
      <Snow />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query HomeContent($locale: String) {
    homePageData: markdownRemark(frontmatter: { pageKey: { eq: "page_home" }, locale: { eq: $locale } }) {
      fields {
        slug
      }
      frontmatter {
        pageKey
        seo_title
        seo_desc
        title
        text
      }
    }
  }
`
